import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const AdminImportData = () => {
    const navigate= useNavigate()
  useEffect(() => {
    navigate("/admin/import");
  }, []);
    
  return (
    <div>AdminImportData</div>
  )
}

export default AdminImportData