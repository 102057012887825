function ErrorPage() {
    return (
        <div className="page-wrap d-flex flex-row align-items-center" style={{ minHeight: '100vh' }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-1 d-block">Error</span>
                        <div className="mb-4 lead">Something went wrong, please try again.</div>
                        <a className="btn btn-sm btn-outline-primary" href="/">Back to Home</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;