import React, { Suspense } from "react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { BrowserRouter } from "react-router-dom";
import { UserContext } from "./core/hooks/userContext";
import useFindUser from "./core/hooks/useFindUser";

import AppRoutes from "./app-routing";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { user, setUser } = useFindUser();

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <UserContext.Provider value={{ user, setUser }}>
          <Suspense fallback={
            <div style={{ position: "fixed", width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                <div className="lds-dual-ring"></div>                
              </div>
            </div>
          }>
            <AppRoutes userInfo={user} />
          </Suspense>
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );

}

export default App;