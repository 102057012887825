import { useState, useEffect } from 'react';

export default function useFindUser() {
    const [user, setUser] = useState(null);

    useEffect(() =>{
        async function findUser() {
            let loggedInUser = JSON.parse(localStorage.getItem('user'));
            if(loggedInUser) setUser(loggedInUser);
            return await loggedInUser;
        }
        findUser();  
    }, []);
    return {
        user,
        setUser
    }
}