import React, { useState, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import AdminImportData from "./core/components/importData/AdminImportData.jsx";
import ErrorPage from "./shared/components/error-page/not-found.js";
const Dashboard = lazy(() => import('./core/components/dashboard/Dashboard.jsx'));
const Profile = lazy(() => import("./core/components/profile/profile"));
const Registrations = lazy(() => import("./core/components/registrations/registrations"));
const Submissions = lazy(() => import("./core/components/submissions/submissions"));
const Notification = lazy(() => import("./core/components/notifications/notification"));
const Subscriptions = lazy(() => import("./core/components/subscriptions/subscriptions"));
const ImportData = lazy(() => import("./core/components/importData/ImportData"));
const ManageAccount = lazy(() => import("./core/components/account/manage-account.component"));
const Layout = lazy(() => import('./core/components/layout/layout'));
const Login = lazy(() => import('./core/components/account/login.component'));
const Logout = lazy(() => import('./core/components/account/logout.component'));
const OTP = lazy(() => import('./core/components/account/otp.component'));
const NotFound = lazy(() => import('./shared/components/not-found/not-found'));
const PrivateRoute = lazy(() => import("./core/components/PrivateRoute/PrivateRoute"));
const ViewRegistration = lazy(() => import("./core/components/registrations/view-registration"));
const NewRegistration = lazy(() => import("./core/components/registrations/new-registration"));
const Alerts = lazy(() => import("./core/components/alerts/alert-component.js"));
const Reporting= lazy(() => import("./core/components/reporting/reporting.js"));
const Logs = lazy(() => import("./core/components/logs/Logs"));
const Patient = lazy(() => import("./core/components/registrations/patient"));

const UploadDocument = lazy(() => import("./core/components/document/upload-document"));


export default function AppRoutes({ userInfo }) {
    const [viewRegistrationUpdated, setViewRegistrationUpdated] = useState(false);
    const handleViewRegistrationUpdate = () => {
        setViewRegistrationUpdated(!viewRegistrationUpdated);
    };
    return (
        <Routes>
            <Route index element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/otp" element={<OTP />} />
            <Route element={<Layout onViewRegistrationUpdate={handleViewRegistrationUpdate} />}>
                <Route path="/" element={<PrivateRoute />}>
                    {getPrivateRoutes((userInfo), handleViewRegistrationUpdate)}
                </Route>
            </Route>
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

const getPrivateRoutes = (user,handleViewRegistrationUpdate) => {
    const authenticatedRoutes = [<Route index element={<Registrations />} key={0} />];
    const routeAuthMatrix = [
        {
            name: 'dashboard',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN', 'ROLE_SCIENCE37', 'ROLE_ADRENAS', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_INVESTIGATOR', 'ROLE_SPONSOR'],
            component: <Dashboard />
        },
        {
            name: 'candidate',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_SCIENCE37', 'ROLE_ADMIN', 'ROLE_ADRENAS', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_INVESTIGATOR', 'ROLE_SPONSOR', 'ROLE_PATIENT_FAMILY'],
            component: <Registrations status=''/>
        },
        {
            name: '/candidate/under-consideration',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_SCIENCE37', 'ROLE_ADMIN', 'ROLE_ADRENAS', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_INVESTIGATOR', 'ROLE_SPONSOR'],
            component: <Registrations status='under consideration'/>
        },
        {
            name: '/candidate/pending-referral',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_SCIENCE37', 'ROLE_ADMIN', 'ROLE_ADRENAS', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_INVESTIGATOR', 'ROLE_SPONSOR'],
            component: <Registrations status='pending referral'/>
        },
        {
            name: '/candidate/referred',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_SCIENCE37', 'ROLE_ADMIN', 'ROLE_ADRENAS', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_INVESTIGATOR', 'ROLE_SPONSOR'],
            component: <Registrations status='referred'/>
        },
        {
            name: '/candidate/not-under-consideration',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_SCIENCE37', 'ROLE_ADMIN', 'ROLE_ADRENAS', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_INVESTIGATOR', 'ROLE_SPONSOR'],
            component: <Registrations status='not under consideration'/>
        },
        {
            name: 'submissions',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_SCIENCE37', 'ROLE_ADMIN', 'ROLE_ADRENAS', 'ROLE_COMMUNICATION_COORDINATOR'],
            component: <Submissions />
        },
        {
            name: 'subscriptions',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <Subscriptions />
        },
        {
            name: 'notifications',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_SPONSOR'],
            component: <Notification />
        },
        {
            name: 'admin/import',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <ImportData />
        },
        {
            name: 'admin/logs',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <Logs />
        },
        {
            name: 'admin/alerts',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <Alerts />
        },
        {
            name: 'Reporting',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <Reporting />
        },
        
        {
            name: 'admin/manage-account',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <ManageAccount />
        },
        {
            name: 'profile',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN', 'ROLE_SCIENCE37', 'ROLE_ADRENAS', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_INVESTIGATOR', 'ROLE_SPONSOR', 'ROLE_PATIENT_FAMILY'],
            component: <Profile />
        },
        {
            name: 'admin',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <AdminImportData />
        },
        {
            name: 'edit/:id',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN', 'ROLE_COMMUNICATION_COORDINATOR', 'ROLE_INVESTIGATOR', 'ROLE_SPONSOR', 'ROLE_PATIENT_FAMILY'],
            component: <ViewRegistration onViewRegistrationUpdate={handleViewRegistrationUpdate} />
        },
        {
            name: 'new-registration',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <NewRegistration  />
        },
        {
            name: 'patient',
            visibleOn: ['ROLE_PATIENT_FAMILY'],
            component: <Patient />
        },
        {
            name: 'document/:id',
            visibleOn: ['ROLE_MBLHEALTH', 'ROLE_ADMIN'],
            component: <UploadDocument />
        },
        {
            name: 'logout',
            visibleOn: ['ROLE_PATIENT_FAMILY'],
            component: <Logout />
        }
    ];
    if (user) {
        routeAuthMatrix.map(route => {
            user.roles?.map((item, key) => {
                if (route.visibleOn.includes(item)) {
                    authenticatedRoutes.push(<Route path={route.name} element={route.component} key={key + 1} />);
                }
            });
        })
    }
    return authenticatedRoutes;
};


